<template>
  <div> 
        <IconTopTable />
        
        <div class="content-table-results mt-2">
            <table class="table table-fixed table-stripes">
                <ColumnColGroup page="result" :column="data.column" :default_column="data.default_column" />
                <ColumnThead page="result" :column="data.column" :default_column="data.default_column" />
                <tbody>
                    <template v-if="data.data.data && data.data.data.length > 0">
                        <tr v-for="(item, key) in data.data.data" :key="key" >                
                            
                            <td>
                                <label class="table-check">
                                    <input type="checkbox" v-model="select_export" :value="item.drug_id">
                                    <span class="checkbox-table"></span>
                                </label>
                            </td>
                            <td>
                                <router-link :to="'/detail/'+item.drug_id+'/'+item.agency_model.toLowerCase()" target="_blank" rel="noopener">
                                    <div class="icon-fiche"></div>
                                </router-link>
                                <div v-if="isAdmin()">
                                    <a :href="modifyPage('nices', item.drug_id)" target="_blank" rel="noopener"> 
                                        <em class="icon-edit"></em>
                                    </a>
                                </div>
                            </td>
                            <template v-for="(column) in data.column" :key="column.code" >
                                <!-- <td v-if="column.Status && column.code == 'nice_001'" :code="column.code">
                                    <span v-html="icon_link(item.drug_id,'nice')"></span>
                                </td> -->
                                <td v-if="column.Status && column.code == 'nice_002'" :code="column.code">
                                    <span v-html="ema_id(item.links)"></span>
                                </td>    
                                <td v-else-if="column.Status && column.code == 'nice_003'" :code="column.code">
                                    {{item.drug_id}}
                                </td>     
                                <td v-else-if="column.Status && column.code == 'nice_004'" :code="column.code">
                                    {{check_empty(item.category)}}
                                </td>      
                                <td v-else-if="column.Status && column.code == 'nice_005'" :code="column.code">
                                    {{check_empty(item.dci_new)}}
                                </td>      
                                <td v-else-if="column.Status && column.code == 'nice_006'" :code="column.code">
                                    <span v-html="rembursement_status(item.reimbursement_decision, 'rb_')"></span>
                                </td>  
                                <td v-else-if="column.Status && column.code == 'nice_044'" :code="column.code">
                                    <span v-html="check_empty(overview_regulatory_status(item.current_ma_status))"></span>
                                </td>    
                                <td v-else-if="column.Status && column.code == 'nice_007'" :code="column.code">
                                    <span v-html="column_status(item)"></span>
                                </td>      
                                <td v-else-if="column.Status && column.code == 'nice_008'" :code="column.code">                                 
                                    <span :class="nice_class_approved(item.decision)">{{check_empty(item.decision)}}</span>
                                </td>      
                                <td v-else-if="column.Status && column.code == 'nice_009'" :code="column.code">
                                    {{check_empty(item.name)}}
                                </td>      
                                <td v-else-if="column.Status && column.code == 'nice_010'" :code="column.code">
                                    {{check_empty(item.dci)}}
                                </td>      
                                <td v-else-if="column.Status && column.code == 'nice_011'" :code="column.code">
                                    {{check_empty(item.firm)}}
                                </td> 
                                <td v-else-if="column.Status && column.code == 'nice_045'" :code="column.code">
                                    {{check_empty(DDMMMYY(item.nice.expected_date))}}
                                </td>       
                                <td v-else-if="column.Status && column.code == 'nice_012'" :code="column.code">
                                    {{check_empty(DDMMMYY(item.submission_date))}} 
                                </td>      
                                <td v-else-if="column.Status && column.code == 'nice_013'" :code="column.code">
                                    {{check_empty(DDMMMYY(item.decision_date))}} 
                                </td>      
                                <td v-else-if="column.Status && column.code == 'nice_014'" :code="column.code">
                                    {{check_empty(DDMMMYY(item.ma_date))}} 
                                </td>      
                                <td v-else-if="column.Status && column.code == 'nice_015'" :code="column.code">                                
                                    <span v-html="check_empty(array(item.atc))"></span>
                                </td>      
                                <td v-else-if="column.Status && column.code == 'nice_016'" :code="column.code">
                                    <span v-html="check_empty(array(item['indexation_'+$i18n.locale]))"></span>                      
                                </td>      
                                <td v-else-if="column.Status && column.code == 'nice_017'" :code="column.code">
                                    <span :class="color_type_limitation(item.prioritis_hta_index)">{{check_empty(item.prioritis_hta_index)}}</span>                                
                                </td>    
                                <template  v-else-if="column.Status && column.code == 'nice_018'" >                             
                                    <td  :key="column.code+'_019'" code="nice_019">
                                        <Readmore :longText="item.target_population"/>  
                                    </td>      
                                    <td :key="column.code+'_020'" code="nice_020">
                                        <Readmore :longText="item.prescription_and_delivery_conditions"/>  
                                    </td>      
                                    <td :key="column.code+'_021'" code="nice_021">
                                        <Readmore :longText="item.access_scheme"/>  
                                    </td>      
                                    <td :key="column.code+'_022'" code="nice_022">
                                        <Readmore :longText="item.outcome_level"/>  
                                    </td>      
                                </template>

                                <td v-else-if="column.Status && column.code == 'nice_023'" :code="column.code">     
                                    <Readmore :longText="item.rationale_and_comments_html_en"/>         
                                </td>      
                                <td v-else-if="column.Status && column.code == 'nice_024'" :code="column.code">  
                                    {{check_empty(item.nice.process) }}
                                    <span v-html="show_text(item.nice.cdf, 'CDF reconsideration')"></span>
                                    <span v-html="show_text(item.nice.check_cdf, 'CDF')"></span>
                                </td>      
                                <td v-else-if="column.Status && column.code == 'nice_025'" :code="column.code">
                                    {{check_empty(item.guidance_id)}}
                                </td>      
                                <td v-else-if="column.Status && column.code == 'nice_026'" :code="column.code">                               
                                    <Readmore :longText="item['indication_en']"/>   
                                </td>      
                                <td v-else-if="column.Status && column.code == 'nice_027'" :code="column.code">                                
                                    <span v-if="$i18n.locale !== 'fr'" v-html="check_empty(array(item.classification_en))"  ></span>
                                    <span v-else v-html="check_empty(array(item.classification_fr))"></span>
                                </td>      
                                <td v-else-if="column.Status && column.code == 'nice_028'" :code="column.code">
                                    {{check_empty_with_locale(item.mk_orphelin)}}
                                </td>      
                                <td v-else-if="column.Status && column.code == 'nice_029'" :code="column.code">                                                                   
                                    <Readmore :longText="show_text(item.nice.patient_access_scheme_pas,'Patient access scheme')+
                                                show_text(item.nice.simple_discount_scheme,'Simple discount scheme')+
                                                show_text(item.nice.complex_scheme,'Complex scheme')+
                                                show_text(item.nice.commercial_access_agreement,'Commercial access agreement')+
                                                item.nice.comment_commercial_arrangements" />                   
                                </td>  
                                <td v-else-if="column.Status && column.code == 'nice_030'" :code="column.code">
                                    {{check_empty(DDMMMYY(item.nice.date_eams_granted))}}
                                </td>    
                                <td v-else-if="column.Status && column.code == 'nice_031'" :code="column.code">
                                    <span v-html="nct_number(item.nct_numbers)" @click="drug_id = item.drug_id"></span>
                                </td> 
                                <td v-else-if="column.Status && column.code == 'nice_032'" :code="column.code">
                                    <span v-html="check_empty(treatment_line(item.treatment_line))"></span>
                                </td>                                      
                                <td v-else-if="column.Status && column.code == 'nice_033'" :code="column.code">
                                    {{check_empty(item.specificity)}}
                                </td>      
                                <td v-else-if="column.Status && column.code == 'nice_034'" :code="column.code">
                                    {{check_empty(item.administration)}}
                                </td>      
                                <template  v-else-if="column.Status && column.code == 'nice_035'">                
                                    <!-- data-model -->
                                    <td  :key="column.code+'_036'" code="nice_036">
                                        {{check_empty(DDMMMYY(item.hta_process_date))}} 
                                    </td>      
                                    <td  :key="column.code+'_037'" code="nice_037">
                                        <span v-html="hta_process_status(item, 'hta_')"></span>
                                    </td>      
                                    <td  :key="column.code+'_038'" code="nice_038">
                                        {{check_empty(DDMMMYY(item.rembursement_process_date))}} 
                                    </td>      
                                    <td   :key="column.code+'_039'" code="nice_039">
                                        <span v-html="rembursement_status(item.reimbursement_decision, 'rb_')"></span>
                                    </td>      
                                </template>
                                <template  v-else-if="column.Status && column.code == 'nice_040'">   
                                    <td :key="column.code+'_041'" code="nice_041">
                                        <!-- modal_conclusion -->
                                        <span data-bs-toggle="modal" data-bs-target="#modal-hta-status" @click="hta_status_modal(item)" v-html="hta_process_status_2(item)"></span>
                                    </td>      
                                    <td :key="column.code+'_042'" code="nice_042">                                  
                                        <span data-bs-toggle="modal" data-bs-target="#modal-hta-status" @click="hta_status_modal(item)" v-html="rembursement_status_2(item)"></span>
                                    </td>      
                                    <td :key="column.code+'_043'" code="nice_043">                       
                                        {{datediff(item.ma_date, item.rembursement_process_date)}}
                                    </td>  
                                </template>
                            </template>

                        </tr>
                    </template>

                    <template v-else><tr><td colspan="99">{{$t('No Data')}}</td></tr></template>

                </tbody>

            </table>
        </div>  
        <ModalHTAstatus :item="item_hta_status" /> 
        <ModalEssaisClinique :id="drug_id" agency="Nice" /> 
    </div>
</template>

<script>
import IconTopTable from '@/components/result/icon_top_table.vue';
import ColumnColGroup from '@/components/elements/column_colgroup.vue';
import ColumnThead from '@/components/elements/column_thead.vue'; 
import Readmore from '@/components/elements/readmore.vue';
import ModalHTAstatus from '@/components/result/modal_hta_status.vue';
import { isAdmin, nice_class_approved, column_status, hta_process_status, hta_process_status_2, 
        rembursement_status, rembursement_status_2, overview_regulatory_status, modifyPage, ema_id, 
        icon_link, DDMMMYY, color_type_limitation, check_empty, nct_number, treatment_line, datediff, 
        array, check_empty_with_locale } from '@/utils'
import ModalEssaisClinique from '@/components/elements/modal_essais_clinique.vue'
export default {
    name: "Nice",
    components: {
        IconTopTable,
        ColumnColGroup,
        ColumnThead,
        Readmore,
        ModalHTAstatus,
        ModalEssaisClinique
    },
    data() {
        return{ 
            item_hta_status : {},
            drug_id : 0 //for essais clinique popup
        }
    },
    computed: {                
        data(){
            return this.$store.getters['result/data'];
        },
        select_export: {
            set(val){ this.$store.dispatch("result/select_export", val) },
            get(){ 
                if(this.$store.getters['result/select_export_checkAll']){
                    return true
                }else{
                    return this.$store.getters['result/select_export'] ? this.$store.getters['result/select_export'].listID : null 
                }
            }
        }      
    }, 
    methods:{          
        column_status, 
        hta_process_status,
        hta_process_status_2,
        rembursement_status,
        rembursement_status_2,
        isAdmin,
        ema_id,
        icon_link,
        DDMMMYY,
        check_empty,
        nct_number,
        treatment_line,
        datediff,
        array,
        color_type_limitation,
        check_empty_with_locale,
        show_text: function(value,txt) {                               
            let text_return = '';    
            if(value == '1') {
                text_return = "<p>"+txt+"</p>"
            } 
            return text_return
        },
        nice_class_approved,
        hta_status_modal(item){
            this.item_hta_status = item
        },
        overview_regulatory_status,
        modifyPage       
    }
}
</script>

<style scoped>
/* CSS for fixed height of table for show in one page */
@media only screen and (max-width: 862px){
    .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 70vh;
    }
}
@media only screen and (min-height: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .content-table-results{
        max-height: 75vh;
    }
}
</style>